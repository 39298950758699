/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {IAutoCompleteItem} from './dtos/IAutoComplete';
import {debounce} from 'lodash';

@Component({
  selector: 'canvas-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AutoCompleteComponent,
    },
  ],
})
export class AutoCompleteComponent implements ControlValueAccessor, OnInit {
  @Output() getSuggestions = new EventEmitter<string>();
  @Input() suggestions: Array<IAutoCompleteItem> | undefined;

  @Input() inputId: string | undefined;
  @Input() isRequired: boolean | undefined;
  @Input() className: string | undefined;
  @Input() isDisabled: boolean | undefined;
  @Input() selectedVal: IAutoCompleteItem | undefined;
  selectedItem: IAutoCompleteItem = { text: '' } as IAutoCompleteItem;
  //@Output() itemSelected = new EventEmitter<IAutoCompleteItem>();

  touched = false;
  disabled = false;
  selectedItemFocus = -1;
  private readonly debouncedGetSuggestions: any;

  constructor() {
    this.debouncedGetSuggestions = debounce(this.getSuggestionsInternal, 150);
  }

  get text() {
    return this.selectedItem?.text || '';
  }

  get isValid(): boolean {
    const val =
      this.isRequired === true &&
      this.selectedItem?.id !== undefined &&
      this.selectedItem?.id !== null &&
      this.selectedItem?.id > 0;

    return val;
  }

  get displayClass(): string {
    const defaultClass = 'input w-full max-w-xs';
    return this.className !== undefined && this.className !== null
      ? this.className
      : defaultClass;
  }

  ngOnInit(): void {
    if (this.selectedVal?.id) {
      this.selectedItem = {
        text: this.selectedVal?.text,
        id: this.selectedVal?.id,
      } as IAutoCompleteItem;
      this.handleSelectSuggestion(this.selectedVal);
    }
  }

  onChange = (val: IAutoCompleteItem) => {};

  onTouched = () => {};

  writeValue(obj: any): void {
    // this method is called by the Forms module to write a value into a form control
    // throw new Error('Method not implemented.');
    if (typeof obj === 'object' && obj) {
      this.selectedItem = obj as IAutoCompleteItem;
    } else if (typeof obj === 'number' && obj === this.selectedItem?.id) {
      this.selectedItem = this.selectedItem as IAutoCompleteItem;
    } else {
      this.selectedItem = { text: '' } as IAutoCompleteItem;
    }
  }

  registerOnChange(fn: any): void {
    // When a form value changes due to user input, we need to report the value back to the parent form.
    // This is done by calling a callback, that was initially registered with the control using the registerOnChange method
    // throw new Error('Method not implemented.');
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // throw new Error('Method not implemented.');
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
    this.disabled = isDisabled;
  }

  handleSelectSuggestion(suggestion: IAutoCompleteItem) {
    this.suggestions = [];
    this.selectedItem.id = suggestion.id;
    this.selectedItem.text = suggestion.text;
    this.selectedItemFocus = -1;
    this.selectedItem = suggestion;

    this.onChange(suggestion);
  }

  handleKeyUp(e: any) {
    const val = e.target.value;
    this.selectedItemFocus = -1;

    const suggestions = this.suggestions || [];
    const selectedItemFocus = this.selectedItemFocus;

    if (suggestions.length > 0) {
      if (e.keyCode === 40) {
        /*If the arrow DOWN key is pressed,
            increase the currentFocus variable:*/
        if (selectedItemFocus < suggestions.length - 1) {
          this.selectedItemFocus = selectedItemFocus + 1;
        }
      } else if (e.keyCode === 38) {
        //up
        /*If the arrow UP key is pressed,
            decrease the currentFocus variable:*/
        if (selectedItemFocus > 0) {
          this.selectedItemFocus = selectedItemFocus - 1;
        }
      } else if (e.keyCode === 13) {
        /*If the ENTER key is pressed, prevent the form from being submitted,*/
        e.preventDefault();
        if (selectedItemFocus > -1) {
          /*and simulate a click on the "active" item:*/
          this.selectedItemFocus = selectedItemFocus + 1;
          this.handleSelectSuggestion(suggestions[selectedItemFocus]);
        }
      } else {
        this.selectedItemFocus = -1;
      }
    }

    const text = this.text;
    if (e.keyCode !== 38 && e.keyCode !== 40 && e.keyCode !== 13) {
      this.selectedItem.text = val;
      if (val.length > 2) {
        this.debouncedGetSuggestions(text);
      } else {
        // console.log('clear suggestions');
        this.suggestions = [];
      }
    }
  }

  handleChange = (e: any) => {
    const val = e.target.value;
    this.selectedItem.text = val;
    this.selectedItem.id = undefined;
    if (!val) {
      this.handleSelectSuggestion(this.selectedItem);
    }
  };

  private getSuggestionsInternal(text: string) {
    if (this.getSuggestions) {
      this.getSuggestions.emit(text);
    }
  }
}
