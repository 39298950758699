<div class="autocomplete">
  <input
    [id]="inputId"
    type="search"
    placeholder="Type here"
    [ngClass]="displayClass"
    (keyup)="handleKeyUp($event)"
    (change)="handleChange($event)"
    [value]="text"
    autocomplete="off"
    [required]="isRequired"
    [disabled]="isDisabled"
    (search)="handleChange($event)"
  />
  <div class="autocomplete-items" *ngIf="suggestions && suggestions.length > 0">
    <div
      key="{ind}"
      (click)="handleSelectSuggestion(s)"
      *ngFor="let s of suggestions"
      [ngClass]="[s.id === selectedItemFocus ? 'autocomplete-active' : '']"
    >
      {{ s.text }}
    </div>
  </div>
</div>
