import { DialogModule } from '@angular/cdk/dialog';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';

import {
  AUTH_CONFIG,
  BffUserService,
  ConfigurationService,
  DefaultHeadersInterceptor,
  UiModule,
} from '@canvas/four-r-digital';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { getAuthUserUrl } from './common/ApiKeys';
import { LoginComponent } from './static/login/login.component';

export const appInitialisation = (configService: ConfigurationService) => {
  return async () => {
    const epoch = new Date().getTime();
    const versionConfig = await fetch(`/assets/config.json?cache=${epoch}`);

    const json = await versionConfig.json();
    configService.setConfig(json);
    console.log(
      `%c Version: ${configService.config.version} `,
      'background: green; color: white; display: block;'
    );
  };
};

@NgModule({
  declarations: [AppComponent, LoginComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    UiModule,
    ToastrModule.forRoot({
      timeOut: 3000,
    }),
    BrowserAnimationsModule,
    DialogModule,
    NgxSpinnerModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitialisation,
      deps: [ConfigurationService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultHeadersInterceptor,
      multi: true,
    },
    { provide: AUTH_CONFIG, useValue: { authUrl: getAuthUserUrl() } },
    BffUserService,
    provideHttpClient(withInterceptorsFromDi()) // Directly add the result here
  ],
})
export class AppModule {}
