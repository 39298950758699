import {
  Component,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';

import { filter } from 'rxjs';
import { ConfigurationService, SpinnerService } from '@canvas/four-r-digital';

@Component({
  selector: 'canvas-admin-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'canvas-admin';

  constructor(
    public viewContainerRef: ViewContainerRef,
    public readonly spinner: SpinnerService,
    private readonly router: Router,
    private readonly config: ConfigurationService
  ) {}

  ngOnInit(): void {

    this.router.events.pipe(
      filter(e => e instanceof RouterEvent),
    ).subscribe(e => {
      if(e instanceof NavigationStart){
        this.spinner.show("Loading...");
      }

      if(e instanceof NavigationEnd){
        this.spinner.hide();
        window.scroll(0,0);
      }
    })
  }

}
