import { Component } from '@angular/core';

import { BffUserService } from '../../services/auth/bff.user.service';

@Component({
  template: `
      <div class="container mx-auto p-60">
        <h1 class="text-5xl text-center my-10">You are about to log out</h1>
        <div class="wrap flex justify-center space-x-5">
          <button class="btn btn-xl btn-primary" (click)="logout()">Continue</button>
          <button class="btn btn-xl btn-outline">Go Back</button>
        </div>
      </div>

  `,
  styles: [],
})
export class LogoutComponent {

  constructor(
    private readonly bffService: BffUserService
  ){}

  public logout(): void{
    this.bffService.logout();
  }

}
