import { camelCase } from 'lodash';

export function camelizeKeys(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v));
  } else if (obj != null && typeof obj === 'object') {
    return Object.keys(obj).reduce((result: any, key: string) => {
      const camelizedKey = camelCase(key);
      result[camelizedKey] = camelizeKeys(obj[key]);
      return result;
    }, {});
  }
  return obj;
}
