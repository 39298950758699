<div class="map-wrapper">
    <div class="map" id="map" class="match-parent"></div>

    <div id="menu">
        <div class="flex">
            <div>
                <input id="satellite-streets-v12" type="radio" name="rtoggle" 
                    value="satellite" [checked]="isChecked('satellite-streets-v12')" 
                    (click)="handleChangeStyle('satellite-streets-v12')">
                <label for="satellite-streets-v12" class="ml-1">satellite</label>    
            </div>
            <div class="ml-2">
                <input id="streets-v12" type="radio" name="rtoggle" value="streets" [checked]="isChecked('streets-v12')" 
                (click)="handleChangeStyle('streets-v12')">
                <label for="streets-v12" class="ml-1">streets</label>    
            </div>
        </div>
    </div>
</div>
