import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface IConfiguration{
  version: string;
  themeLogo: string;  
  themeColour: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private httpClient: HttpClient) {
  }

  public config!: IConfiguration;
  public isLoading = false;

  public setConfig(config: IConfiguration){
    this.config = config;
  }

  public loadConfigFrom(url: string) {
    this.isLoading = true;
    this.httpClient.get(url).subscribe(r => {
      this.config = {...this.config, ...r};
    });
  }
}
