import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import * as mapboxgl from 'mapbox-gl';

import {
  CoordinatePair,
  PositionalData,
} from '@canvas/canvas-lib';

@Component({
  selector: 'canvas-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  map: mapboxgl.Map | undefined;
  @Input() accessToken = '';
  @Input() usePolygon = false;
  @Input() positionalData: PositionalData | undefined;
  @Input() zoom = 8;
  @Output() mapClicked = new EventEmitter<CoordinatePair>();

  currentStyle = 'satellite-streets-v12';

  private style = 'mapbox://styles/mapbox/satellite-streets-v12';

  get centre(): mapboxgl.LngLatLike | undefined {
    if (this.positionalData && this.positionalData?.coordinates.length > 0) {
      return [this.positionalData?.coordinates[0].longitude, this.positionalData?.coordinates[0].latitude];
    }

    if (this.positionalData && this.positionalData?.polygons.length > 0) {
      return [this.positionalData?.polygons[0][0].longitude, this.positionalData?.polygons[0][0].latitude];
    }

    // Arlesford :-)
    return [
      -1.1625276688730537, 51.0889253250744,
    ];
  }

  ngOnInit() {
    mapboxgl as typeof mapboxgl;

    this.map = new mapboxgl.Map({
      container: 'map',
      accessToken: this.accessToken,
      style: this.style,
      center: this.centre,
      zoom: this.zoom,
    });

    this.map.on('mouseup', (e: any) => {
      const lngLat = e.lngLat.wrap();
      const coords = {
        longitude: lngLat.lng,
        latitude: lngLat.lat,
      } as CoordinatePair;
      console.log(coords);
      this.mapClicked.emit(coords);
    });

    this.map.addControl(new mapboxgl.NavigationControl());

    this.map.on('load', () => {
      this.drawMarkersAndPolygons();
    });

    this.map.on('styledata', () => {
      this.drawMarkersAndPolygons();
    });
  }

  private drawMarkersAndPolygons() {
    const coords = this.positionalData?.coordinates;
    const markers = this.positionalData?.coordinates
      ? this.coordinatesToLngLats(this.positionalData?.coordinates)
      : [];

    const polygons = this.positionalData?.polygons
      ? this.positionalData?.polygons.map((p) => this.coordinatesToLngLats(p))
      : [];

    if (this.map?.getSource('site1') === undefined) {
      if (this.usePolygon && markers.length > 0) {
        this.drawPolygon('site1', this.map as any, markers);
      } else if (coords) {
        this.addMarkersToMap(this.map as any, markers);
      }
    }

    if (
      polygons &&
      polygons.length > 0 &&
      this.map?.getSource('polygon-1') === undefined
    ) {
      for (let i = 0; i < polygons.length; i++) {
        this.drawPolygon(`polygon-${i + 1}`, this.map as any, polygons[i]);
      }
    }
  }

  private coordinatesToLngLats(coordinates: Array<CoordinatePair>) {
    const coords = coordinates;
    let lngLats = [] as mapboxgl.LngLatLike[];
    if (coords && coords.length > 0) {
      // this.centre = [coords[0].longitude, coords[0].latitude];
      lngLats = coords.map((c) => {
        return [c.longitude, c.latitude] as mapboxgl.LngLatLike;
      });
    }

    return lngLats;
  }

  private drawPolygon(
    label: string,
    map: mapboxgl.Map,
    positions: mapboxgl.LngLatLike[]
  ) {
    const coordinates = positions.map((p: any) => {
      return [p[0], p[1]]; //[p.lng, p.lat];
    });

    map.addSource(label, {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: [coordinates],
        },

      },
    });

    map.addLayer({
      id: label,
      type: 'fill',
      source: label, // reference the data source
      layout: {},
      paint: {
        'fill-color': '#0080ff', // blue color fill
        'fill-opacity': 0.3,
      },
    });

    // Add a black outline around the polygon.
    map.addLayer({
      id: `${label}-outline`,
      type: 'line',
      source: label,
      layout: {},
      paint: {
        'line-color': '#000',
        'line-width': 1,
      },
    });
  }

  private addMarkersToMap(
    map: mapboxgl.Map,
    coordinates: mapboxgl.LngLatLike[]
  ) {
    for (const coordinatePair of coordinates) {
      const marker = new mapboxgl.Marker()
        .setLngLat(coordinatePair)
        .addTo(map)
        .setPopup(new mapboxgl.Popup().setHTML('<h4>Device 1</h4>'))
      marker.getElement().addEventListener("click", ()=>{
        console.log('Hello!');
      })
    }
  }

  handleChangeStyle(style: string) {
    this.currentStyle = style;
    this.style = `mapbox://styles/mapbox/${style}`;
    this.map?.setStyle(this.style);
    this.drawMarkersAndPolygons();
  }

  isChecked(val: string) {
    const isChecked = val === this.currentStyle;
    return isChecked;
  }
}
