import { Component } from '@angular/core';
import { BffUserService } from '@canvas/four-r-digital';


@Component({
  template: ` <div
    class="hero min-h-screen"
    style="background-image: url(/assets/imgs/buyer-dashboard-bg.png);"
  >
    <div class="hero-overlay bg-opacity-60"></div>
    <div class="hero-content text-center text-white">
      <div class="">
        <h1 class="mb-5 text-6xl font-bold">Welcome to Canvas Admin</h1>
        <p class="mb-5 text-3xl">
          To use this site, you must be logged in, continue with the button
          below
        </p>
        <button
          (click)="login()"
          class="btn btn-primary text-white h-16 w-52 text-2xl canvas-admin-orange-button"
        >
          Login
        </button>
      </div>
    </div>
  </div>`,
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  constructor(private readonly service: BffUserService) {}

  public login() {
    this.service.login();
  }
}
