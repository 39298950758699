import {
  Inject,
  Injectable,
} from '@angular/core';

import {
  map,
  Observable,
  of,
  tap,
} from 'rxjs';

import { ApiClient } from '../api-client.service';

export type User = {
  isAuthenticated: boolean;
  claims?: { type: string; value: string }[];
};

export const AUTH_CONFIG = 'auth_config';

export type AuthConfig = {
  authUrl: string;
  loginUrl: string;
  logoutUrl: string;
};

@Injectable()
export class BffUserService {
  constructor(
    private client: ApiClient,
    @Inject(AUTH_CONFIG) private authConfig: AuthConfig
  ) {}

  private _storedUser: User | undefined;

  public get user(): Observable<User> {
    if (this._storedUser) {
      return of(this._storedUser);
    }

    return this.client.get<User>(this.authConfig.authUrl).pipe(
      tap((user: User) => (this._storedUser = user)),
      map((user: User) => user)
    );
  }

  isAuthenticated(): Observable<boolean> {
    return this.user.pipe(map((user) => user.isAuthenticated));
  }

  isNotAuthenticated(): Observable<boolean> {
    return this.isAuthenticated().pipe(
      map((isAuthenticated) => !isAuthenticated)
    );
  }

  login() {
    console.log('In BffUserService: login');
    window.location.href = this.authConfig.loginUrl || 'api/Auth/Login';
  }

  logout() {
    window.location.href = this.authConfig.logoutUrl || 'api/Auth/Logout';
  }

  matchesClaim(claim: string): Observable<boolean> {
    return this.matchesClaims([claim]);
  }

  matchesClaims(claims: string[]): Observable<boolean> {
    return this.user.pipe(
      map((user) => {
        if (!user.isAuthenticated) return false;

        for (const claim of claims) {
          const hasClaim = this.userHasClaim(user, claim);
          if (!hasClaim) return false;
        }

        return true;
      })
    );
  }

  private userHasClaim(user: User, claim: string): boolean {
    if (!user.claims) return false;
    return user.claims.findIndex((c) => c.value === claim) > -1;
  }

  public get userName(): Observable<string | undefined> {
    return this.user.pipe(map(user => {
      if (!user.isAuthenticated) return undefined;
      return user.claims?.find(c => c.type === 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name')?.value;
    }));
  }
}
