import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  public text?: string;

  constructor(
    private spinner: NgxSpinnerService
  ) { }


  public show(text?: string){
    this.text = text;
    this.spinner.show();
  }

  public hide(){
    this.spinner.hide();
  }


}
