import { ListItem } from "../models";

export class EnumHelper {

    public static enumToList(enm: any): ListItem[] {
        const list: ListItem[] = [];
        for (const enmVal in enm) {
            const isValueProperty = parseInt(enmVal, 10) >= 0;
            if (isValueProperty) {
                const numId = parseInt(enmVal);
                const text = this.camelPad(enm[enmVal]);
                list.push({ id: numId, text: text } as ListItem);
            }
        }
        return list;
    }

    public static getEnumItem(key = 0, listItems: ListItem[]): ListItem | undefined {
        if (key && listItems && listItems.length > 0) {
            const item = listItems.find(x => x.id.toString() === key.toString());
            if (item) {
                return item;
            }
        }
        return undefined;
    }

    public static getEnumText(key = 0, listItems: ListItem[]) {
        if (listItems && listItems.length > 0) {
            const item = listItems.find(x => x.id.toString() === key.toString());
            if (item) {
                return item.text;
            }
            return 'Unknown';
        }
        return 'New';
    }


    public static getEnumKey(value = '', listItems: ListItem[]) {
        if (value && listItems && listItems.length > 0) {
            const item = listItems.find(x => x.text === value);
            if (item) {
                return item.id;
            }
        }
        return 0;
    }

    public static camelPad(str: string ){
        return str
            .replace(/([a-z\d])([A-Z])/g, '$1 $2')
            .replace(/^./, function(str){ return str.toUpperCase(); })
        .trim();
    }

}
