import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadComponent } from './upload.component';

@NgModule({
  declarations: [UploadComponent],
  imports: [CommonModule, FontAwesomeModule],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  exports: [UploadComponent],
})
export class UploadModule {

}
