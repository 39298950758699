import {
  inject,
  NgModule,
} from '@angular/core';
import {
  RouterModule,
  Routes,
} from '@angular/router';

import {
  BffUserService,
  LogoutComponent,
} from '@canvas/four-r-digital';

import { environment } from '../environments/environment';
import { LoginComponent } from './static/login/login.component';

const routes: Routes = [
  {
    path: 'main',
    loadChildren: () =>
      import('./modules/main/main.module').then((m) => m.MainModule),
      canMatch: [()=> inject(BffUserService).matchesClaim(environment.authClaims[0])]
  },
  {
    path: 'main',
    component: LogoutComponent,
    canMatch: [()=> inject(BffUserService).isAuthenticated()]

  },
  {
    path: 'main',
    component: LoginComponent,
    canMatch: [()=> inject(BffUserService).isNotAuthenticated()]
  },
  {
    path: '',
    redirectTo: 'main',
    pathMatch: 'full',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
